// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activity-tsx": () => import("./../../../src/pages/activity.tsx" /* webpackChunkName: "component---src-pages-activity-tsx" */),
  "component---src-pages-communication-tsx": () => import("./../../../src/pages/communication.tsx" /* webpackChunkName: "component---src-pages-communication-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-special-cafe-aqours-tsx": () => import("./../../../src/pages/special/cafe-aqours.tsx" /* webpackChunkName: "component---src-pages-special-cafe-aqours-tsx" */),
  "component---src-templates-afterword-tsx": () => import("./../../../src/templates/afterword.tsx" /* webpackChunkName: "component---src-templates-afterword-tsx" */)
}

