/**
 * https://chakra-ui.com/guides/integrations/with-gatsby#usage
 */
import { extendTheme } from "@chakra-ui/react"

export default extendTheme({
  styles: {
    global: {
      body: {
        bg: null
      }
    }
  }
})
